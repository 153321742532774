import cn from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { EBreakpoints } from 'constants/breakpoints';
import { externalServicesUrls } from 'constants/externalServicesUrls';

import { ALink } from 'components/UIKit/ALink';
import { BodyM, T } from 'components/basic/Typography';

import { isBrowser, isTestEnvironment } from 'utils/environment';

import { getPlaceSlug } from './helpers/getPlaceSlug';
import type { IBannerData, ILitresBanner } from './types';

import s from './LitresBanner.module.scss';

interface ILitresBannerProps {
  headerBannerData?: ILitresBanner | null;
  isHeader?: boolean;
  isMainPage?: boolean;
}

export const LitresBanner: FC<ILitresBannerProps> = ({
  headerBannerData,
  isHeader,
  isMainPage,
}) => {
  const [bannerData, setBannerData] = useState<ILitresBanner | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const copyTextToClipboard = async (): Promise<void> => {
    await navigator.clipboard.writeText(bannerData?.copyLink ?? '');
  };

  const isMobile = isBrowser && window.innerWidth < EBreakpoints.MD;

  const getLitresBanner = async (): Promise<Response> => {
    const placeSlug = getPlaceSlug(isMainPage);

    let url = isTestEnvironment()
      ? externalServicesUrls.litresBanner.master(placeSlug)
      : externalServicesUrls.litresBanner.production(placeSlug);

    const options = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'app-id': '2',
        Accept: 'application/json',
      },
    };

    if (isMobile) {
      url = url + '?device_type=mobile';
    }

    return await fetch(url, options);
  };

  const getBannerInfo = async (): Promise<void> => {
    try {
      const data = await getLitresBanner();
      const result = (await data.json()) as {
        payload: { data: IBannerData[] };
      };
      const payload = result.payload.data;

      if (payload.length !== 0) {
        const {
          banner_title,
          image_url,
          transition_link,
          advertising_marking,
        } = payload[0];
        setBannerData({
          title: banner_title,
          url: image_url,
          link: transition_link,
          mark: advertising_marking,
          copyLink: transition_link,
        });
      }
    } catch (e) {
      setBannerData(null);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isHeader) {
      getBannerInfo();
    }
  }, []);

  useEffect(() => {
    if (headerBannerData) {
      setBannerData(headerBannerData);
    }
  }, [headerBannerData]);

  const advertContent = (
    <BodyM>
      {bannerData?.mark}
      <br /> <br />
      <ALink href="" onClick={copyTextToClipboard}>
        Скопировать ссылку
      </ALink>
    </BodyM>
  );

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return bannerData ? (
    <div className={s.wrapper}>
      {bannerData.link ? (
        <ALink href={bannerData.link} target="_blank">
          {/*TODO - заменить стандатрный <img> на <Image> некста | PS-3917*/}
          <img
            src={bannerData.url}
            alt={bannerData.title}
            className={cn(s.picture, { [s.pictureHeader]: headerBannerData })}
          />
        </ALink>
      ) : (
        <img
          src={bannerData.url}
          alt={bannerData.title}
          className={cn(s.picture, { [s.pictureHeader]: headerBannerData })}
        />
      )}
      {bannerData.mark && (
        <div
          className={cn(s.nameplate, { [s.nameplateHeader]: headerBannerData })}
          onClick={handleOpen}
        >
          <T>Реклама</T>{' '}
        </div>
      )}
      {isOpen && (
        <div className={s.overlay} onClick={handleClose}>
          <div className={s.content}>{advertContent}</div>
        </div>
      )}
    </div>
  ) : null;
};

import Image from 'next/image';
import { FC } from 'react';

import s from './HeaderLogo.module.scss';

interface IHeaderLogo {
  isPublisher?: boolean;
}

export const HeaderLogo: FC<IHeaderLogo> = ({ isPublisher }: IHeaderLogo) => {
  return (
    <>
      <div className={s.logoFull}>
        <Image
          src={`/icons/${isPublisher ? 'litresLogo' : 'logo'}.svg`}
          aria-hidden="true"
          width={isPublisher ? 172 : 237}
          height={isPublisher ? 32 : 22}
          alt=""
        />
      </div>

      <div className={s.logoMobile}>
        <Image
          src={`/icons/${isPublisher ? 'litresLogoSmall' : 'logoSmall'}.svg`}
          aria-hidden="true"
          width={30}
          height={30}
          alt=""
        />
      </div>

      <span className="visually-hidden">
        {isPublisher ? 'Литрес' : 'Литрес Самиздат'}
      </span>
    </>
  );
};

import { ILitresBanner } from 'modules/litres-banner/components/LitresBanner/types';
import type { FC } from 'react';

import { UserInfoState } from 'sp-redux/slices/userInfo/types';

import { PublisherHeader } from './PublisherHeader';
import { SelfpubHeader } from './SelfpubHeader';

interface IHeaderProps {
  userInfo?: UserInfoState;
  className?: string;
  bannerData?: ILitresBanner | null;
}

export const Header: FC<IHeaderProps> = props => {
  const isPublisher = props.userInfo?.is_publisher;

  return isPublisher ? (
    <PublisherHeader {...props} />
  ) : (
    <SelfpubHeader {...props} />
  );
};

import { useMediaQuery } from '@mui/material';
import cn from 'classnames';
import { getFirstLetters } from 'modules/header/helpers/getFirstLetters';
import type { FC } from 'react';

import { EBreakpoints } from 'constants/breakpoints';

import { Icon, TIconTypes } from 'components/UIKit/Icon';
import { Illustration } from 'components/basic/Illustration';
import { BodyM700 } from 'components/basic/Typography';

import { mediaBreakpointDown } from 'utils/mediaBreakpointDown';

import { EProfileAvatarTypes } from './constants';

import s from './ProfileAvatar.module.scss';

export interface IProfileAvatarProps {
  type?: EProfileAvatarTypes;
  name?: string;
  hasAlert?: boolean;
  onClick: () => void;
  notificationMarker?: TIconTypes;
}

export const ProfileAvatar: FC<IProfileAvatarProps> = ({
  type = EProfileAvatarTypes.primary,
  hasAlert,
  name,
  onClick,
  notificationMarker,
}) => {
  const isMobile = useMediaQuery(mediaBreakpointDown(EBreakpoints.LG));

  return (
    <div
      className={cn(s.avatar, {
        [s.avatarPrimary]: type === EProfileAvatarTypes.primary,
        [s.avatarSecondary]: type === EProfileAvatarTypes.secondary,
      })}
      onClick={onClick}
    >
      {name ? (
        <BodyM700 className={s.name}>{getFirstLetters(name)}</BodyM700>
      ) : (
        <Illustration
          className={s.humanVector}
          imageType="avatar"
          width={!isMobile ? 24 : 20}
          height={!isMobile ? 26 : 22}
        />
      )}

      {hasAlert && (
        <div className={s.alert}>
          <Icon icon="errorFilled16x16" color="var(--palettes-red-100)" />
        </div>
      )}
      {notificationMarker && (
        <div className={s.notification}>
          <Icon icon={notificationMarker} size={20} />
        </div>
      )}
    </div>
  );
};
